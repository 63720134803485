.imeg {
  width: 100%;
  height: auto;
  margin: 0;
  margin-top: 50px;

  background-size: auto 100%;
  background-position: 0 0;

  &::after {
    content: '';

    display: block;
    padding-top: 100%;
  }
}

.header_block {
  justify-content: flex-start;
}

.text_club p:nth-of-type(1) {
  width: 60%;
}

.before_item {
  width: 24%;
}

@media screen and (max-width: 1180px) {
  .imeg {
    max-width: 300px;
  }
}

@include tablet-md {
  .main_list ul .before_item {
    margin-bottom: 60px;
  }

  .text_club {
    margin-top: 70px;
  }
}

@include phone-lg {
  .main_list ul .before_item:last-child {
    margin-bottom: 0;
  }

  .text_club {
    margin-top: 20px;
    flex-direction: column;

    p {
      margin-bottom: 10px;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        width: 100%;
      }
    }
  }

  .before_item:before {
    min-width: 101px;
  }
}