.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.65);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.popup--hidden {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, z-index 0s linear 0.3s;
}

.popup__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.popup__window {
  position: relative;
  overflow-y: auto;
  width: 616px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 50px);

  background-size: cover;
  background-position: center;
  background-color: $white;

  &--promo {
    width: 804px;
    height: 800px;
    padding: 50px 75px 150px;
  }
}

.popup__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.popup__close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transition: opacity 0.15s ease;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.75;
  }
}

.popup__plane {
  margin-bottom: 20px;
}

.popup__text-wrap {
  padding: 15px;
}

.popup__title {
  display: block;
  font-weight: $bold;
  font-size: 44px;
  line-height: 1.2;
  text-align: center;
}

.popup__subtitle {
  display: block;
  font-weight: $medium;
  font-size: 26px;
  line-height: 1.16;
  text-align: center;
  margin-top: 15px;
  padding: 0 50px;
}

.popup__description {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
}

.popup__form {
  margin-top: 100px;
}

.popup__success {
  display: none;
}

.popup__window--success {
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    display: none;
  }

  .popup__success {
    display: block;
  }

  .popup__form {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    left: -999px;
    top: -999px;
  }

  .popup__close {
    display: block;
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

@include screen-md {
  .popup__window {
    &--promo {
      width: 600px;
      height: 500px;
      padding: 50px 50px 60px;
    }
  }

  .popup__title {
    font-size: 32px;
  }

  .popup__subtitle {
    font-size: 22px;
    padding: 0;
  }

  .popup__form {
    margin-top: 50px;
  }

  .popup__close {
    top: 0;
    right: 0;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

@include tablet-sm {
  .popup__window {
    &--promo {
      padding: 40px 15px;
    }
  }

  .popup__title {
    font-size: 26px;
  }

  .popup__subtitle {
    font-size: 18px;
  }

  .popup__form {
    margin-top: 30px;
  }
}
