.checkout {
  margin-bottom: 20px;

  .order-popup {
    margin-bottom: 20px;
  }

  .row.fixed-height-row-big > div {
    height: auto;
  }

  .form__data-group {
    margin-top: 20px;
  }

  .fixed-height-row-big {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  .checkout {
    margin-top: 20px;
  }

  .checkout {
    .container {
      width: 100%;
    }
  }

  .checkout {
    .input-small > input {
      width: 100%;
    }

    .input-small .select2-container {
      width: 100% !important;
    }

    .checkbox .checkbox-button {
      width: 100%;
    }
  }
}

@include tablet-lg {
  .checkout--row {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    margin-top: 20px;

    .order-popup {
      width: 100%;
    }

    .input-small-promo {
      width: 276px;
      margin-right: auto;
    }

    .custom-button-small {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 8px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .auth-wrapper {
    .input-small {
      max-width: 411px;
    }
  }

  .checkout {
    .custom-button {
      max-width: 411px;
    }

    .custom-label {
      display: none;
    }
  }
}

@include tablet-md {
  .checkout {
    .margin-fix {
      margin-top: 20px;
    }
  }

  .checkout--row {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-small-promo {
      width: 70%;
      margin-right: 0;
    }

    .custom-button-small {
      width: 70%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .top-line {
    width: 33% !important;
  }

  .checkout .fixed-height-row-big {
    display: block;
  }
}

@include phone-lg {
  .checkout--row {
    .input-small-promo {
      width: 100%;
    }

    .custom-button-small {
      width: 100%;
    }
  }

  .column-register-title {
    margin: 0;
    padding: 0;

    font-weight: 700;

    font-size: 17px;
    color: #ffffff;

    &::before {
      display: none;
    }
  }

  .checkout {
    .form__action-group {
      padding: 15px;

      background-color: #336666;
      border-radius: 15px;
    }
    
    .form__action-btn {
      color: #ffffff;
      border-bottom-color: #fff;
    }

    .checkbox .checkbox-button {
      display: inline-block;
      width: auto;

      background-color: transparent;
      color: #000000;
      border: none;
    }

    .form__data-group {
      margin-top: 0;
    }
  }

  .checkout--row .order-popup {
    width: 276px;
  }

  .not-auth-actions {
    width: 100%;
  }
}