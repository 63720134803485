.main_list ul li {
  margin-right: 40px;
  flex-grow: 0;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1440px) {
  .shut_up {
    margin-left: 0;
  }
}

@include screen-md {
  .shut_up {
    width: 90%;
    height: auto;

    &::after {
      content: '';

      display: block;
      padding-top: 100%;
    }
  }
}

@media screen and (max-width: 1180px) {
  .block_favor {
    padding-bottom: 64.5px;

    background-position: 60% 50% !important;

    .main_list ul li {
      width: 40%;
      margin-right: 0;

      &:not(:nth-child(2n)) {
        margin-right: 20%;
      }
    }
  }

  .shut_up {
    display: none;
  }

  .main_flex {
    flex-direction: column;
  }

  .main_text_site {
    width: 100%;
  }

  .header_block {
    width: 100%;
    margin-bottom: 20px;

    h3 {
      padding-bottom: 10px;
    }
  }
}

@include tablet-lg {
  .become_button {
    margin-top: 40px;
  }

  .block_favor {
    .main_list ul li {
      width: 25%;

      &:not(:nth-child(2n)) {
        margin-right: calc(25% / 2);
      }

      &:not(:nth-child(3n)) {
        margin-right: calc(25% / 2);
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

@include tablet-md {
  .main_list ul li{
    width: 40%;
    margin-right: 0;

    &:not(:nth-child(2n)) {
      margin-right: 20%;
    }
  }

  .become_button {
    margin-top: 70px;
  }

  .block_favor {
    .main_list ul li {
      width: 40%;

      &:not(:nth-child(2n)) {
        margin-right: 20%;
      }

      &:not(:nth-child(3n)) {
        margin-right: 20%;
      }

      &:nth-child(3n) {
        margin-right: 20%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .block_white.mini {
    .main_list ul li {
      &:not(:last-child) {
        margin-bottom: 110px;
      }
    }
  }

  .buy_block {
    .main_list ul li {
      &:not(:last-child) {
        margin-bottom: 100px;
      }
    }
  }

  .main_text_site {
    .telehone {
      font-size: 36px;
    }

    .mail {
      font-size: 26px;
    }
  }

  .main_list.main_list_price_plan {
    ul li {
      margin-bottom: 40px;
    }

    .submit {
      margin-top: 180px;
    }
  }
}

@include phone-lg {
  .header_block h3 {
    margin-right: 0;
    padding-bottom: 4px;

    font-size: 26px;
  }

  .main_flex p {
    font-size: 18px;
  }

  .main_list ul li{
    position: relative;
    left: -60px;
    top: 25px;

    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    &:not(:nth-child(2n)) {
      margin-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .before_item:before {
    position: relative;
    left: 60px;
    top: -25px;
  }

  .block_favor {
    .main_list ul li {
      width: 100%;

      &:not(:nth-child(2n)) {
        margin-right: 0;
      }

      &:not(:nth-child(3n)) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .block_white.mini {
    .main_list ul li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .block_white.learn {
    .main_list ul li {
      position: static;

      display: block;

      text-align: center;

      .price_plan_images {
        width: 190px;
        margin: 0 auto;
        margin-top: 40px;
      }
    }
  }

  .buy_block {
    .main_list ul li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .main_text_site {
    .telehone {
      font-size: 28px;
    }

    .mail {
      font-size: 22px;
    }
  }

  .block_white.contact {
    padding-bottom: 100px;
  }

  .main_list.main_list_price_plan {
    ul li {
      margin-bottom: 60px;
    }

    .submit {
      margin-top: 220px;
    }
  }
}