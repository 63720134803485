.navbar {
  .navbar-brand {
    font-size: 26px;
  }
}

.form__action-btn {
  display: none;

  border: none;
  outline: none;
  box-shadow: none;
  background: none;

  &::after {
    content: 'Показать';
  }
}

.form__data-group.collapse {
  display: block;
  height: auto !important;
}

.form__data-group {
  margin-top: 40px;
}

.row.fixed-height-row > div {
  height: auto;
}

.login__socials {
  margin-top: 20px;
  margin-left: -3px;
}

@media screen and (max-width: 1200px) {
  .navbar {
    .container {
      padding-top: 20px;
    }
  }
}

@include tablet-md {
  .navbar {
    .navbar-brand {
      font-size: 22px;
    }
  }

  .custom-label .select2-container {
    width: 60% !important;
  }

  .count-materials a{
    display: flex !important;
    align-items: center;

    &::after {
      position: static !important;

      margin-left: 10px;
    }
  }
}

@include phone-lg {
  .navbar {
    .container {
      padding-top: 10px;
    }

    .navbar-brand {
      font-size: 18px;
    }
  }

  .header-container {
    .lk-top-title {
      margin-bottom: 10px;

      font-size: 19px !important;
      font-weight: bold;
    }
  }

  .top-line {
    width: 70px !important;
  }

  .lk-column-title {
    position: relative;

    font-size: 17px;
    font-weight: bold;
    text-transform: none;

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;

      display: block;
      width: 70px;
      height: 2px;

      background-color: #336666;
    }
  }

  .custom-label {
    display: flex;
    flex-direction: column;

    & > input {
      width: 100%;
      padding: 5px;
    }

    & > label {
      font-weight: normal;
    }
  }

  .input-small:last-child {
    padding-bottom: 0;
  }

  .form__group {
    margin-bottom: 20px;
  }

  .form__data-group.collapse {
    display: none;
  }

  .form__action-btn {
    display: block;
  }

  .form__action-group {
    display: flex;
    margin-top: 20px;
    padding-bottom: 20px;

    .lk-column-title {
      width: 70%;
      margin: 0;
    }

    .form__action-btn {
      width: auto;
      margin-left: auto;
      padding: 0;

      border-bottom: 1px dashed #000;

      transition: border-color .2s;

      &::after {
        content: 'Скрыть';
      }

      &.collapsed {
        &::after {
          content: 'Показать';
        }
      }

      &:hover,
      &:focus {
        border-bottom-color: transparent;
      }
    }
  }

  .form__data-group {
    margin-top: 0;
  }

  .form__data-group.collapse.in {
    display: block;
  }

  [hidden] {
    display: none;
  }

  .login__socials {
    margin-top: 0;
  }

  .custom-label {
    .select2-container {
      width: 100% !important;
    }
    .select2-selection__rendered {
      padding: 5px;

      line-height: 1.6 !important;
    }

    .select2-selection {
      height: 34px;
    }

    .select2-selection__arrow {
      top: 4px !important;
    }
  }
}