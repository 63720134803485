.flex_header {
  flex-wrap: wrap;
}

.container__inner {
  display: flex;
  align-items: center;
}

.slide_block {
  display: flex;
  align-items: center;
  padding-bottom: 110px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 90% 50%;
}

.footer-info-box {
  display: none;
}

header {
  .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;

    max-width: 1240px;
    width: 100%;
    margin: 0 auto;

    transform: translateY(-50%);
  }

  .slider_h2 {
    max-width: 1040px;
    margin: 0 auto;
  }

  .owl-next {
    right: 60px;
  }

  .owl-prev {
    left: 60px;
  }

  .owl-dot {
    box-shadow: 0 0 0 1px rgba(#fff, 0.5);
  }
}

.txt_icon > span {
  padding-left: 10px;
  padding-right: 10px;
}


.item_slide {
  height: auto;

  background-repeat: no-repeat;
  background-size: contain !important;

  &::after {
    content: '';

    display: block;
    padding-top: 34%;
  }
}

footer {
  display: flex !important;
  flex-wrap: wrap;

  .text_iron {
    margin-right: 10px;
  }

  .list_center {
    margin-left: 10px;
    margin-right: 10px;
  }

  .soc_block {
    margin-left: 10px;
  }
}

.footer__developer--mobile {
  display: none;
}

@include screen-md {
  .personal-warning__text {
    padding-right: 0;
  }

  header {
    .slider_h2 {
      max-width: 980px;
    }

    .owl-next {
      right: 68px;
    }

    .owl-prev {
      left: 68px;
    }
  }
}

@media screen and (max-width: 1200px) {
  header {
    height: 470px;

    .slider_h2 {
      max-width: 750px;
      padding-left: 10px;
      padding-right: 10px;

      font-size: 48px !important;
      line-height: 1.3 !important;
    }

    .owl-nav {
      top: 55%;
    }
  }

  .slide_block {
    height: 470px;
    padding-top: 220px;
  }

  .top_header .logo {
    width: 130px;
  }

  .username_header {
    margin-right: 40px;
  }

  .soc_block li:first-child {
    margin-right: 20px;
  }

  .promo_slide {
    max-height: 100%;
  }

  .buttons_block {
    .button {
      padding: 10px;
    }
  }
}

@include tablet-lg {
  header {
    .slider_h2 {
      max-width: 550px;
      font-size: 36px !important;
    }
  }

  .emblem_block {
    display: none;
  }

  .mail {
    font-size: 14px;
  }

  .tel {
    font-size: 14px;
  }

  .txt_tel {
    font-size: 13px;
  }

  .buttons_block {
    display: flex;
    flex-wrap: wrap;

    .button {
      width: calc(100% / 2);
      height: auto;
    }
  }
  #owl-carousel3 {
    margin-bottom: 60px;
  }

  footer {
    padding-top: 0;

    .list_center {
      order: 1;
      width: 50%;
      margin-left: 0;
      margin-right: 5%;

      div, ul {
        width: 100%;
      }

      li {
        &:not(:last-child) {
          margin-right: 5%;
        }
      }
    }

    .soc_block {
      order: 2;

      width: 45%;
      margin-left: 0;
      justify-content: flex-end;
    }

    .text_iron {
      order: 3;
      margin-top: 10px;
    }
  }

  .news h5 {
    width: 80%;
  }
}

@include tablet-md {
  .manu-header {
    width: auto;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 20px;

    li a {
      font-size: 20px;
    }
  }

  header {
    .slider_h2 {
      max-width: 370px;
      font-size: 28px !important;
    }

    .owl-nav {
      top: 62%;
    }
  }

  .slide_block {
    padding-top: 270px;
  }

  .header-info-box {
    display: flex;
    flex-direction: column;
  }

  .mail {
    margin-top: 12px;
  }

  .manu-header {
    right: 45px;
  }

  .head_h3 h3 {
    font-size: 36px;
  }

  .look a {
    font-size: 20px;
  }

  .news h5 {
    font-size: 19px;
  }

  footer {
    justify-content: center;

    .list_center {
      width: 100%;
      margin-right: 0;
      justify-content: center;

      ul {
        display: flex;
        justify-content: center;
      }
    }

    .soc_block {
      width: 100%;
      margin-top: 20px;
      justify-content: center;
    }

    .text_iron {
      margin-right: 0;
    }
  }
}

@include tablet-sm {
  .material_block {
    .head_h3 {
      flex-wrap: wrap;

      .look {
        margin-left: auto;
        margin-top: 5px;
      }
    }
  }

  //.header-info-box {
  //  display: none;
  //}


  .top_header .logo {
    margin-right: auto;
  }

  header {
    .slider_h2 {
      max-width: 340px;
    }

    .owl-next {
      right: 38px;
    }

    .owl-prev {
      left: 38px;
    }
  }

  .fab-catalog {
    width: 34px;
    left: calc(100% - 34px);

    span {
      margin-left: -103px;
    }
  }

  .header-custom-link {
    height: 34px;
    padding: 2px 10px;

    span {
      font-size: 16px;
    }
  }

  .news h5 {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {


  .header-info-box {
    display: none;
  }

  .footer-info-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 30px auto;

    .tel_block {
      margin-right: 0;
    }

    .tel {
      color: #5a5f61;
    }

    .txt_tel {
      color: #5a5f61;
    }

    .mail {
      color: #5a5f61;
      margin-left: auto;
    }
  }
}

@include phone-lg {

  .footer-info-box {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;

    .mail {
      margin-left: 0;
    }
  }

  .manu-header {
    top: -25px;
    left: 0;
    right: 0;
  }

  .username_header {
    margin-right: 20px;
  }

  .slide_block {
    padding-top: 17px;
    padding-bottom: 0;
    height: 210px;
  }

  header {
    height: auto;

    .layout {
      min-height: 0;
      height: 210px;
    }

    .slider_h2 {
      max-width: 100%;
      padding: 0 20px;

      font-size: 20px !important;
    }

    .owl-prev,
    .owl-next {
      width: 26px;
      height: 40px;

      background-size: contain;
      background-repeat: no-repeat;
    }

    .owl-prev {
      left: 10px;
    }

    .owl-next {
      right: 10px;
    }

    .owl-nav {
      top: 44%;
    }
  }

  .main_block {
    padding-top: 10px;
  }

  .fab-catalog {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .header-custom-link {
    display: none;
  }

  .buttons_block {
    .button {
      width: 90%;
      min-height: 60px;
      margin: 0 auto;
      padding: 5px;
      flex-direction: row;
      justify-content: flex-start;

      border-width: 3px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .button_image {
      background-image: none !important;
    }

    .icon_butt {
      height: auto;

      img {
        width: 40px;
        height: auto;
      }
    }

    .txt_icon {
      margin-top: 0;

      & > span {
        text-align: left;
        font-size: 18px !important;
        color: #b24449 !important;
      }
    }
  }

  .news_section {
    flex-direction: column;

    .news {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      p {
        margin-top: 5px;
      }

      h5 {
        width: 100%;
      }
    }
  }

  .head_h3 {
    justify-content: space-between;
    align-items: center;
  }

  .head_h3 h3 {
    padding-bottom: 7px;

    font-size: 26px;
  }

  .look a {
    font-size: 18px;
  }

  footer {
    justify-content: flex-start;

    .list_center {
      ul {
        flex-direction: column;
        align-items: flex-start;

        li {
          margin-right: 0;

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
    .soc_block {
      order: 2;
      justify-content: flex-start;

      li {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &:nth-child(4) a {
          width: 26px;
          height: 26px;

          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      li a {
        font-size: 26px;
      }
    }

    .text_iron {
      order: 4;
      margin-top: 20px;
    }
  }

  .privacy-policy {
    flex-direction: column;
  }

  .footer__developer {
    display: none;
  }

  .footer__developer--mobile {
    display: block;
    margin-top: 5px;
    width: 100%;
    order: 3;
  }

  .material_block {
    padding-top: 40px;
  }

  .owl-dots {
    flex-wrap: wrap;

    .owl-dot {
      margin-right: 30px;
    }
  }
}

@keyframes sheen {
  0% {
    transform: translateX(-50px) rotate(60deg);
  }
  30% {
    transform: translateX(100vw) rotate(60deg);
  }
  100% {
    transform: translateX(100vw) rotate(60deg);
  }
}
