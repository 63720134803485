.news-list__inner {
  max-width: 1240px;
  width: 100%;
  padding: 0 10px;
}

@media screen and (max-width: 1200px) {
  .news-list {
    margin-top: 20px;
  }
}

@include tablet-lg {
  .news-list__title {
    width: 80%;
  }
}

@include tablet-sm {
  .news-list__title {
    width: 90%;
  }
}

@include phone-lg {
  .news-list__item {
    width: 100%;
  }

  .news-list__title {
    width: 100%;
  }

  .news-list__item img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}