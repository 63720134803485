
.popup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-form__input-title {
  display: block;
  width: 100%;
  font-size: 32px;
  text-align: center;
  margin-bottom: 10px;
}

.popup-form__input-wrap {
  width: 100%;
}

.popup-form__input {
  width: 100%;
  height: 95px;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.2);
  color: $white;
  font-size: 40px;
  border: 0;
}

.popup-form__submit {
  width: 460px;
  height: 100px;
  color: $white;
  font-size: 42px;
  margin: 50px auto 0;
  text-align: center;
  background-color: rgba(255,0,0,0.5);
  transition: background-color 0.1s ease;
  text-transform: uppercase;
  font-weight: $semibold;
  border: 0;

  &:hover,
  &:focus {
    outline: none;
    background-color: rgba(255,0,0,0.7);
  }

  &:active {
    background-color: rgba(255,0,0,1);
  }
}

.popup-form__error {
  color: $error-color;
  margin-top: 15px;
  text-align: center;

  &--hidden {
    display: none;
  }
}

@include screen-md {
  .popup-form__input-title {
    font-size: 18px;
  }

  .popup-form__input {
    height: 50px;
    font-size: 20px;
  }

  .popup-form__submit {
    width: 100%;
    height: 50px;
    font-size: 22px;
    margin: 30px auto 0;
  }
}

@include tablet-sm  {
  .popup-form__input-title {
    font-size: 16px;
  }
}
