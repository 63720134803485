.order__table-td {
  text-align: left;
}

.table-row-info__materials-btn {
  display: none;
  font-family: "Panton", Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 25px 10px;
  color: #336666;
  border: 1px solid #336666;
  transition: .25s all ease;
  margin-top: 10px;
}

.table-row-info__materials-btn:hover, .table-row-info__materials-btn:focus {
  text-decoration: none;
  color: #fff;
  background-color: #336666;
}

.table-row-info__materials-btn.active {
  display: inline-block;
}

@include tablet-lg {
  .order__table {
    th {
      text-align: center;
    }
  }
  .order__table-td {
    text-align: center;
  }

  .order__list {
    .table-row {
      padding: 10px 0;
    }
  }

  .order__pay {
    flex-direction: column;
    align-items: center;
  }

  .order__hidden--lg {
    display: none;
  }
}

@include tablet-md {
  .order__list {
    .table-row {
      background-color: #fff;
    }
  }

  .row.table-row {
    position: relative;

    display: flex;
    margin-bottom: -1px;
    flex-direction: column;
    border: 1px solid lightgrey !important;

    &.order__hidden {
      display: none;
    }

    &.selected-row {
      border-bottom-color: transparent !important;
      background-color: #f0f0f0;

      & + {
        .table-row-info {
          background-color: #f0f0f0;
        }
      }
    }
  }

  .order__hidden {
    display: none;
  }

  .row.table-row > .order__number {
    order: 1;
    position: relative;

    justify-content: flex-start;

    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000000 !important;

    p {
      margin: 0;
    }

    &::before {
      content: 'Заказ №';

      margin-right: 5px;
      align-self: center;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 15px;

      display: block;
      width: 55px;
      height: 2px;

      background-color: #336666;
    }
  }

  .row.table-row > .order__status {
    order: 2;

    height: 35px;
    justify-content: flex-start;

    p {
      margin: 0;
    }

    br {
      display: none;
    }
  }

  .row.table-row > .order__pay {
    order: 3;

    display: flex !important;
    height: 25px;
    flex-direction: row;
    justify-content: flex-start;

    p {
      margin: 0;
    }

    .get_bill {
      margin-left: 20px;
    }
  }

  .row.table-row > .order__date {
    order: 4;

    height: 25px;
    justify-content: flex-start;

    p {
      margin: 0;
    }

    &::before {
      content: 'Дата приобретения';

      margin-right: 5px;
      align-self: center;
    }
  }

  .row.table-row > .order__cost {
    order: 5;

    height: 25px;
    justify-content: flex-start;

    p {
      margin: 0;
    }

    &::before {
      content: 'Стоимость:';

      margin-right: 5px;
      align-self: center;
    }
  }

  .row.table-row > .order__more {
    position: absolute;
    top: 10px;
    right: 10px;

    display: flex !important;

    .show-more-plus {
      font-size: 35px;
    }
  }

  .row.table-row-info {
    margin-bottom: -1px;

    border: 1px solid lightgrey !important;
    border-top: none !important;
    background-color: #fff;
  }

  .order__table {
    display: block;
    width: 80% !important;
    margin: 0 auto;

    border: none;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-direction: column;
    }

    tr {
      display: flex;
      flex-direction: column;

      &:first-of-type {
        padding-top: 10px;

        border-top: 1px solid lightgrey;
      }

      &:not(:last-of-type) {
        padding-bottom: 10px;

        border-bottom: 1px solid lightgrey;
      }

      &:not(:first-of-type) {
        padding-top: 10px;
      }
    }

    td {
      width: 100%;
      display: flex;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      border: none !important;
    }
  }

  .order__list {
    .table-responsive {
      border: none !important;
    }
  }

  .order__table-title {
     &::before {
       content: 'Курс';

       font-weight: bold;

       margin-right: auto;
     }
  }

  .order__table-number {
    &::before {
      content: '№';

      font-weight: bold;

      margin-right: auto;
    }
  }

  .order__table-quarter {
    &::before {
      content: 'Квартал';

      font-weight: bold;

      margin-right: auto;
    }
  }

  .order__table-year {
    &::before {
      content: 'Год';

      font-weight: bold;

      margin-right: auto;
    }
  }

  .order__table-status {
    &::before {
      content: 'Статус';

      font-weight: bold;

      margin-right: auto;
    }
  }
  
  .order__table-td {
    width: 50%;

    text-align: left;
    white-space: normal;
  }
}

@include phone-lg {
  .order__table {
    width: 100% !important;
  }

  .order__table-td {
    width: 60%;
  }
}