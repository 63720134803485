@include tablet-md {
  .auth-wrapper {
    max-width: 411px;

    .input-small input {
      width: 100%;
    }
  }

  .auth__buttons {
    max-width: 411px;
  }
}