.breadcrumbs {
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.breadcrumb-static {
  margin-right: 5px;

  &:after {
    top: 50%;

    line-height: 13px;

    transform: translateY(-50%);
  }
}

@include phone-lg {
  .breadcrumbs + .block_white {
    padding-top: 20px;
  }
}