html,
body {
  height: 100%;
}

body {
  min-width: 320px;
}

.container {
  padding: 0 10px;
}

@media screen and (max-width: 320px) {
  body {
    overflow-x: auto;
  }
}

@import "variables";

@import "mixin";
@import "pages/main";
@import "pages/instructors";
@import "pages/news";
@import "pages/clients";
@import "pages/clubs";
@import "pages/breadcrumbs";
@import "pages/news-single";
@import "pages/lk";
@import "pages/materials";
@import "pages/question";
@import "pages/orders";
@import "pages/catalog";
@import "pages/checkout";
@import "pages/change-password";
@import "pages/auth";
@import "pages/promo";
@import "popup/checkbox";
@import "popup/popup";
@import "popup/popup-form";
@import "popup/promo-popup";
