.promo_header {
  font-size: 30px;
  font-weight: normal;
}

.main_promo {
  padding-top: 57px;
}

.ben_bl {
  width: calc((100% - 10px * 3) / 4);
  margin-bottom: 40px;
  margin-right: 10px;

  img {
    width: 100%;
    height: auto;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }
}

.item_ben {
  height: auto;
}

.benefits_blocks {
  align-items: baseline;
}

.bl_iron {
  width: calc((100% - 62px * 3) / 4);
  margin-right: 62px;

  &:nth-child(4n) {
    margin-right: 0;
  }
}

.posters_blocks {
  align-items: baseline;
}

.poster_bl {
  width: calc((100% - 10px * 5) / 6);
  margin-right: 10px;

  &:nth-child(6n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1180px) {
  .promo_header {
    padding-bottom: 10px;
  }
}

@include tablet-lg {
  .ben_bl {
    width: calc((100% - 10px * 2) / 3);

    &:nth-child(4n) {
      margin-right: 10px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .bl_iron {
    width: calc((100% - 20px * 3) / 4);
    margin-right: 20px;
  }

  .poster_bl {
    width: calc((100% - 10px * 4) / 5);

    &:nth-child(6n) {
      margin-right: 10px;
    }

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}

@include tablet-md {
  .poster_bl {
    width: calc((100% - 10px * 3) / 4);

    &:nth-child(5n) {
      margin-right: 10px;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

@include tablet-sm {
  .ben_bl {
    width: calc((100% - 10px) / 2);

    &:nth-child(3n) {
      margin-right: 10px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .bl_iron {
    width: calc((100% - 20px * 2) / 3);

    &:nth-child(4n) {
      margin-right: 20px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .poster_bl {
    width: calc((100% - 10px * 2) / 3);

    &:nth-child(4n) {
      margin-right: 10px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

@include phone-lg {
  .main_promo {
    padding-top: 20px;
  }

  .promo_header {
    margin-right: 0;
    padding-bottom: 4px;

    font-size: 26px;
  }

  .ben_bl {
    width: 100%;
    margin-right: 0;

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .bl_iron {
    width: calc((100% - 20px) / 2);

    &:nth-child(3n) {
      margin-right: 20px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .poster_bl {
    width: calc((100% - 10px) / 2);

    &:nth-child(3n) {
      margin-right: 10px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}