.checkbox {
  display: block;
  position: relative;
  color: $text-color;
  font-size: 20px;
  line-height: 1.25;
  margin-top: 10px;
  cursor: pointer;

  a {
    color: $pagination-color;
    border-bottom-color: $pagination-color;
  }
}

.checkbox--error {
  &::before {
    border-color: $error-color;
  }
}

.checkbox__text {
  position: relative;
  display: block;
  min-height: 30px;
  padding: 0 0 0 65px;

  a {
    color: $brand-blue;
    border-bottom: 1px solid $brand-blue;
    transition: border-color 0.3s;

    &:hover,
    &:focus {
      border-bottom-color: transparent;
      outline: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 30px;
    height: 30px;
    border: 1px solid $text-color;
    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7px;
    width: 16px;
    height: 16px;
    background: $text-color;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 0.2;
  }
}

.checkbox__hidden-control {
  top: calc(50% + 15px);
  left: 15px;
}

.checkbox__hidden-control:checked + .checkbox__text {
  &::after {
    opacity: 1;
  }
}

@include screen-md {
  .checkbox__text {
    font-size: 16px;
    padding: 0 0 0 45px;

    &::before {
      width: 25px;
      height: 25px;
    }

    &::after {
      left: 6px;
      width: 14px;
      height: 14px;
    }
  }
}
