@include tablet-md {
  .block_white {
    padding-bottom: 102px;
  }
}

@include tablet-sm {
  .item_diploma {
    margin-right: 20px;
  }
}

@include phone-lg {
  .block_white {
    padding-bottom: 32px;
  }

  .block_diploma {
    flex-direction: column;
  }

  .item_diploma {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .download_knob {
    margin-top: 20px;

    a {
      padding: 12px 24px;

      font-size: 16px;
    }
  }
}