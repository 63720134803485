@charset "UTF-8";
html,
body {
  height: 100%; }

body {
  min-width: 320px; }

.container {
  padding: 0 10px; }

@media screen and (max-width: 320px) {
  body {
    overflow-x: auto; } }

.flex_header {
  flex-wrap: wrap; }

.container__inner {
  display: flex;
  align-items: center; }

.slide_block {
  display: flex;
  align-items: center;
  padding-bottom: 110px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 90% 50%; }

.footer-info-box {
  display: none; }

header .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  transform: translateY(-50%); }

header .slider_h2 {
  max-width: 1040px;
  margin: 0 auto; }

header .owl-next {
  right: 60px; }

header .owl-prev {
  left: 60px; }

header .owl-dot {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5); }

.txt_icon > span {
  padding-left: 10px;
  padding-right: 10px; }

.item_slide {
  height: auto;
  background-repeat: no-repeat;
  background-size: contain !important; }
  .item_slide::after {
    content: '';
    display: block;
    padding-top: 34%; }

footer {
  display: flex !important;
  flex-wrap: wrap; }
  footer .text_iron {
    margin-right: 10px; }
  footer .list_center {
    margin-left: 10px;
    margin-right: 10px; }
  footer .soc_block {
    margin-left: 10px; }

.footer__developer--mobile {
  display: none; }

@media screen and (max-width: 1280px) {
  .personal-warning__text {
    padding-right: 0; }
  header .slider_h2 {
    max-width: 980px; }
  header .owl-next {
    right: 68px; }
  header .owl-prev {
    left: 68px; } }

@media screen and (max-width: 1200px) {
  header {
    height: 470px; }
    header .slider_h2 {
      max-width: 750px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 48px !important;
      line-height: 1.3 !important; }
    header .owl-nav {
      top: 55%; }
  .slide_block {
    height: 470px;
    padding-top: 220px; }
  .top_header .logo {
    width: 130px; }
  .username_header {
    margin-right: 40px; }
  .soc_block li:first-child {
    margin-right: 20px; }
  .promo_slide {
    max-height: 100%; }
  .buttons_block .button {
    padding: 10px; } }

@media screen and (max-width: 991px) {
  header .slider_h2 {
    max-width: 550px;
    font-size: 36px !important; }
  .emblem_block {
    display: none; }
  .mail {
    font-size: 14px; }
  .tel {
    font-size: 14px; }
  .txt_tel {
    font-size: 13px; }
  .buttons_block {
    display: flex;
    flex-wrap: wrap; }
    .buttons_block .button {
      width: calc(100% / 2);
      height: auto; }
  #owl-carousel3 {
    margin-bottom: 60px; }
  footer {
    padding-top: 0; }
    footer .list_center {
      order: 1;
      width: 50%;
      margin-left: 0;
      margin-right: 5%; }
      footer .list_center div, footer .list_center ul {
        width: 100%; }
      footer .list_center li:not(:last-child) {
        margin-right: 5%; }
    footer .soc_block {
      order: 2;
      width: 45%;
      margin-left: 0;
      justify-content: flex-end; }
    footer .text_iron {
      order: 3;
      margin-top: 10px; }
  .news h5 {
    width: 80%; } }

@media (max-width: 767px) {
  .manu-header {
    width: auto;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 20px; }
    .manu-header li a {
      font-size: 20px; }
  header .slider_h2 {
    max-width: 370px;
    font-size: 28px !important; }
  header .owl-nav {
    top: 62%; }
  .slide_block {
    padding-top: 270px; }
  .header-info-box {
    display: flex;
    flex-direction: column; }
  .mail {
    margin-top: 12px; }
  .manu-header {
    right: 45px; }
  .head_h3 h3 {
    font-size: 36px; }
  .look a {
    font-size: 20px; }
  .news h5 {
    font-size: 19px; }
  footer {
    justify-content: center; }
    footer .list_center {
      width: 100%;
      margin-right: 0;
      justify-content: center; }
      footer .list_center ul {
        display: flex;
        justify-content: center; }
    footer .soc_block {
      width: 100%;
      margin-top: 20px;
      justify-content: center; }
    footer .text_iron {
      margin-right: 0; } }

@media (max-width: 601px) {
  .material_block .head_h3 {
    flex-wrap: wrap; }
    .material_block .head_h3 .look {
      margin-left: auto;
      margin-top: 5px; }
  .top_header .logo {
    margin-right: auto; }
  header .slider_h2 {
    max-width: 340px; }
  header .owl-next {
    right: 38px; }
  header .owl-prev {
    left: 38px; }
  .fab-catalog {
    width: 34px;
    left: calc(100% - 34px); }
    .fab-catalog span {
      margin-left: -103px; }
  .header-custom-link {
    height: 34px;
    padding: 2px 10px; }
    .header-custom-link span {
      font-size: 16px; }
  .news h5 {
    width: 90%; } }

@media screen and (max-width: 500px) {
  .header-info-box {
    display: none; }
  .footer-info-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 30px auto; }
    .footer-info-box .tel_block {
      margin-right: 0; }
    .footer-info-box .tel {
      color: #5a5f61; }
    .footer-info-box .txt_tel {
      color: #5a5f61; }
    .footer-info-box .mail {
      color: #5a5f61;
      margin-left: auto; } }

@media screen and (max-width: 480px) {
  .footer-info-box {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start; }
    .footer-info-box .mail {
      margin-left: 0; }
  .manu-header {
    top: -25px;
    left: 0;
    right: 0; }
  .username_header {
    margin-right: 20px; }
  .slide_block {
    padding-top: 17px;
    padding-bottom: 0;
    height: 210px; }
  header {
    height: auto; }
    header .layout {
      min-height: 0;
      height: 210px; }
    header .slider_h2 {
      max-width: 100%;
      padding: 0 20px;
      font-size: 20px !important; }
    header .owl-prev,
    header .owl-next {
      width: 26px;
      height: 40px;
      background-size: contain;
      background-repeat: no-repeat; }
    header .owl-prev {
      left: 10px; }
    header .owl-next {
      right: 10px; }
    header .owl-nav {
      top: 44%; }
  .main_block {
    padding-top: 10px; }
  .fab-catalog {
    display: none;
    visibility: hidden;
    opacity: 0; }
  .header-custom-link {
    display: none; }
  .buttons_block .button {
    width: 90%;
    min-height: 60px;
    margin: 0 auto;
    padding: 5px;
    flex-direction: row;
    justify-content: flex-start;
    border-width: 3px; }
    .buttons_block .button:not(:last-child) {
      margin-bottom: 10px; }
  .buttons_block .button_image {
    background-image: none !important; }
  .buttons_block .icon_butt {
    height: auto; }
    .buttons_block .icon_butt img {
      width: 40px;
      height: auto; }
  .buttons_block .txt_icon {
    margin-top: 0; }
    .buttons_block .txt_icon > span {
      text-align: left;
      font-size: 18px !important;
      color: #b24449 !important; }
  .news_section {
    flex-direction: column; }
    .news_section .news:not(:last-child) {
      margin-bottom: 20px; }
    .news_section .news p {
      margin-top: 5px; }
    .news_section .news h5 {
      width: 100%; }
  .head_h3 {
    justify-content: space-between;
    align-items: center; }
  .head_h3 h3 {
    padding-bottom: 7px;
    font-size: 26px; }
  .look a {
    font-size: 18px; }
  footer {
    justify-content: flex-start; }
    footer .list_center ul {
      flex-direction: column;
      align-items: flex-start; }
      footer .list_center ul li {
        margin-right: 0; }
        footer .list_center ul li:not(:last-child) {
          margin-bottom: 5px; }
    footer .soc_block {
      order: 2;
      justify-content: flex-start; }
      footer .soc_block li {
        display: flex;
        align-items: center;
        margin-right: 20px; }
        footer .soc_block li:nth-child(4) a {
          width: 26px;
          height: 26px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center; }
      footer .soc_block li a {
        font-size: 26px; }
    footer .text_iron {
      order: 4;
      margin-top: 20px; }
  .privacy-policy {
    flex-direction: column; }
  .footer__developer {
    display: none; }
  .footer__developer--mobile {
    display: block;
    margin-top: 5px;
    width: 100%;
    order: 3; }
  .material_block {
    padding-top: 40px; }
  .owl-dots {
    flex-wrap: wrap; }
    .owl-dots .owl-dot {
      margin-right: 30px; } }

@keyframes sheen {
  0% {
    transform: translateX(-50px) rotate(60deg); }
  30% {
    transform: translateX(100vw) rotate(60deg); }
  100% {
    transform: translateX(100vw) rotate(60deg); } }

.main_list ul li {
  margin-right: 40px;
  flex-grow: 0; }
  .main_list ul li:nth-child(3n) {
    margin-right: 0; }

@media screen and (max-width: 1440px) {
  .shut_up {
    margin-left: 0; } }

@media screen and (max-width: 1280px) {
  .shut_up {
    width: 90%;
    height: auto; }
    .shut_up::after {
      content: '';
      display: block;
      padding-top: 100%; } }

@media screen and (max-width: 1180px) {
  .block_favor {
    padding-bottom: 64.5px;
    background-position: 60% 50% !important; }
    .block_favor .main_list ul li {
      width: 40%;
      margin-right: 0; }
      .block_favor .main_list ul li:not(:nth-child(2n)) {
        margin-right: 20%; }
  .shut_up {
    display: none; }
  .main_flex {
    flex-direction: column; }
  .main_text_site {
    width: 100%; }
  .header_block {
    width: 100%;
    margin-bottom: 20px; }
    .header_block h3 {
      padding-bottom: 10px; } }

@media screen and (max-width: 991px) {
  .become_button {
    margin-top: 40px; }
  .block_favor .main_list ul li {
    width: 25%; }
    .block_favor .main_list ul li:not(:nth-child(2n)) {
      margin-right: calc(25% / 2); }
    .block_favor .main_list ul li:not(:nth-child(3n)) {
      margin-right: calc(25% / 2); }
    .block_favor .main_list ul li:nth-child(3n) {
      margin-right: 0; } }

@media (max-width: 767px) {
  .main_list ul li {
    width: 40%;
    margin-right: 0; }
    .main_list ul li:not(:nth-child(2n)) {
      margin-right: 20%; }
  .become_button {
    margin-top: 70px; }
  .block_favor .main_list ul li {
    width: 40%; }
    .block_favor .main_list ul li:not(:nth-child(2n)) {
      margin-right: 20%; }
    .block_favor .main_list ul li:not(:nth-child(3n)) {
      margin-right: 20%; }
    .block_favor .main_list ul li:nth-child(3n) {
      margin-right: 20%; }
    .block_favor .main_list ul li:nth-child(2n) {
      margin-right: 0; }
  .block_white.mini .main_list ul li:not(:last-child) {
    margin-bottom: 110px; }
  .buy_block .main_list ul li:not(:last-child) {
    margin-bottom: 100px; }
  .main_text_site .telehone {
    font-size: 36px; }
  .main_text_site .mail {
    font-size: 26px; }
  .main_list.main_list_price_plan ul li {
    margin-bottom: 40px; }
  .main_list.main_list_price_plan .submit {
    margin-top: 180px; } }

@media screen and (max-width: 480px) {
  .header_block h3 {
    margin-right: 0;
    padding-bottom: 4px;
    font-size: 26px; }
  .main_flex p {
    font-size: 18px; }
  .main_list ul li {
    position: relative;
    left: -60px;
    top: 25px;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%; }
    .main_list ul li:not(:nth-child(2n)) {
      margin-right: 0; }
    .main_list ul li:not(:last-child) {
      margin-bottom: 30px; }
  .before_item:before {
    position: relative;
    left: 60px;
    top: -25px; }
  .block_favor .main_list ul li {
    width: 100%; }
    .block_favor .main_list ul li:not(:nth-child(2n)) {
      margin-right: 0; }
    .block_favor .main_list ul li:not(:nth-child(3n)) {
      margin-right: 0; }
    .block_favor .main_list ul li:nth-child(3n) {
      margin-right: 0; }
    .block_favor .main_list ul li:nth-child(2n) {
      margin-right: 0; }
  .block_white.mini .main_list ul li:not(:last-child) {
    margin-bottom: 30px; }
  .block_white.learn .main_list ul li {
    position: static;
    display: block;
    text-align: center; }
    .block_white.learn .main_list ul li .price_plan_images {
      width: 190px;
      margin: 0 auto;
      margin-top: 40px; }
  .buy_block .main_list ul li:not(:last-child) {
    margin-bottom: 30px; }
  .main_text_site .telehone {
    font-size: 28px; }
  .main_text_site .mail {
    font-size: 22px; }
  .block_white.contact {
    padding-bottom: 100px; }
  .main_list.main_list_price_plan ul li {
    margin-bottom: 60px; }
  .main_list.main_list_price_plan .submit {
    margin-top: 220px; } }

.news-list__inner {
  max-width: 1240px;
  width: 100%;
  padding: 0 10px; }

@media screen and (max-width: 1200px) {
  .news-list {
    margin-top: 20px; } }

@media screen and (max-width: 991px) {
  .news-list__title {
    width: 80%; } }

@media (max-width: 601px) {
  .news-list__title {
    width: 90%; } }

@media screen and (max-width: 480px) {
  .news-list__item {
    width: 100%; }
  .news-list__title {
    width: 100%; }
  .news-list__item img {
    display: block;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 767px) {
  .block_white {
    padding-bottom: 102px; } }

@media (max-width: 601px) {
  .item_diploma {
    margin-right: 20px; } }

@media screen and (max-width: 480px) {
  .block_white {
    padding-bottom: 32px; }
  .block_diploma {
    flex-direction: column; }
  .item_diploma {
    margin: 0 auto;
    margin-bottom: 20px; }
  .download_knob {
    margin-top: 20px; }
    .download_knob a {
      padding: 12px 24px;
      font-size: 16px; } }

.imeg {
  width: 100%;
  height: auto;
  margin: 0;
  margin-top: 50px;
  background-size: auto 100%;
  background-position: 0 0; }
  .imeg::after {
    content: '';
    display: block;
    padding-top: 100%; }

.header_block {
  justify-content: flex-start; }

.text_club p:nth-of-type(1) {
  width: 60%; }

.before_item {
  width: 24%; }

@media screen and (max-width: 1180px) {
  .imeg {
    max-width: 300px; } }

@media (max-width: 767px) {
  .main_list ul .before_item {
    margin-bottom: 60px; }
  .text_club {
    margin-top: 70px; } }

@media screen and (max-width: 480px) {
  .main_list ul .before_item:last-child {
    margin-bottom: 0; }
  .text_club {
    margin-top: 20px;
    flex-direction: column; }
    .text_club p {
      margin-bottom: 10px; }
      .text_club p:nth-of-type(1), .text_club p:nth-of-type(2) {
        width: 100%; }
  .before_item:before {
    min-width: 101px; } }

.breadcrumbs .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.breadcrumb-static {
  margin-right: 5px; }
  .breadcrumb-static:after {
    top: 50%;
    line-height: 13px;
    transform: translateY(-50%); }

@media screen and (max-width: 480px) {
  .breadcrumbs + .block_white {
    padding-top: 20px; } }

.single_news_title {
  position: relative;
  margin-right: 54px;
  padding-bottom: 40px;
  font-family: "pfdintextcondpro-medium", sans-serif;
  font-size: 30px;
  font-weight: normal;
  color: #658157;
  line-height: 34px;
  text-transform: uppercase; }
  .single_news_title::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 84px;
    height: 2px;
    background: #658157; }

.single_news_content img {
  width: 50%;
  height: auto; }

#owl-carousel5 .owl-dot {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5); }

@media screen and (max-width: 1180px) {
  .single_news_title {
    padding-bottom: 10px; } }

@media screen and (max-width: 991px) {
  .single_news_content img {
    margin-right: 20px; } }

@media (max-width: 601px) {
  .single_news_content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .single_news_content img {
      float: none;
      display: block;
      margin-right: auto;
      margin-left: auto; } }

@media screen and (max-width: 480px) {
  .single_news {
    margin-top: 20px; }
  .single_news_title {
    margin-right: 0;
    margin-bottom: 40px;
    padding-bottom: 4px;
    font-size: 26px; }
  .single_news_content img {
    width: 80%; }
  #owl-carousel5 .owl-next,
  #owl-carousel5 .owl-prev {
    top: 50%;
    transform: translateY(calc(-50% - 25px)); } }

.navbar .navbar-brand {
  font-size: 26px; }

.form__action-btn {
  display: none;
  border: none;
  outline: none;
  box-shadow: none;
  background: none; }
  .form__action-btn::after {
    content: 'Показать'; }

.form__data-group.collapse {
  display: block;
  height: auto !important; }

.form__data-group {
  margin-top: 40px; }

.row.fixed-height-row > div {
  height: auto; }

.login__socials {
  margin-top: 20px;
  margin-left: -3px; }

@media screen and (max-width: 1200px) {
  .navbar .container {
    padding-top: 20px; } }

@media (max-width: 767px) {
  .navbar .navbar-brand {
    font-size: 22px; }
  .custom-label .select2-container {
    width: 60% !important; }
  .count-materials a {
    display: flex !important;
    align-items: center; }
    .count-materials a::after {
      position: static !important;
      margin-left: 10px; } }

@media screen and (max-width: 480px) {
  .navbar .container {
    padding-top: 10px; }
  .navbar .navbar-brand {
    font-size: 18px; }
  .header-container .lk-top-title {
    margin-bottom: 10px;
    font-size: 19px !important;
    font-weight: bold; }
  .top-line {
    width: 70px !important; }
  .lk-column-title {
    position: relative;
    font-size: 17px;
    font-weight: bold;
    text-transform: none; }
    .lk-column-title::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      display: block;
      width: 70px;
      height: 2px;
      background-color: #336666; }
  .custom-label {
    display: flex;
    flex-direction: column; }
    .custom-label > input {
      width: 100%;
      padding: 5px; }
    .custom-label > label {
      font-weight: normal; }
  .input-small:last-child {
    padding-bottom: 0; }
  .form__group {
    margin-bottom: 20px; }
  .form__data-group.collapse {
    display: none; }
  .form__action-btn {
    display: block; }
  .form__action-group {
    display: flex;
    margin-top: 20px;
    padding-bottom: 20px; }
    .form__action-group .lk-column-title {
      width: 70%;
      margin: 0; }
    .form__action-group .form__action-btn {
      width: auto;
      margin-left: auto;
      padding: 0;
      border-bottom: 1px dashed #000;
      transition: border-color .2s; }
      .form__action-group .form__action-btn::after {
        content: 'Скрыть'; }
      .form__action-group .form__action-btn.collapsed::after {
        content: 'Показать'; }
      .form__action-group .form__action-btn:hover, .form__action-group .form__action-btn:focus {
        border-bottom-color: transparent; }
  .form__data-group {
    margin-top: 0; }
  .form__data-group.collapse.in {
    display: block; }
  [hidden] {
    display: none; }
  .login__socials {
    margin-top: 0; }
  .custom-label .select2-container {
    width: 100% !important; }
  .custom-label .select2-selection__rendered {
    padding: 5px;
    line-height: 1.6 !important; }
  .custom-label .select2-selection {
    height: 34px; }
  .custom-label .select2-selection__arrow {
    top: 4px !important; } }

.material-card {
  position: relative;
  padding-top: 1px; }
  .material-card h1 {
    font-size: 21px; }

.material-card__number {
  font-family: "HelveticaNeueCyr-Light", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: bold; }

.material-card__description {
  display: flex;
  flex-direction: column;
  padding-left: 20px; }

.material-card__link:not(:last-of-type) {
  margin-bottom: 5px; }

.material-card__link--file {
  position: relative; }
  .material-card__link--file::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    display: block;
    width: 15px;
    height: 15px;
    background: url("../img/file.jpg") no-repeat center/contain;
    transform: translateY(-50%); }

.material-card__link--video {
  position: relative; }
  .material-card__link--video::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    display: block;
    width: 15px;
    height: 15px;
    background: url("../img/video.png") no-repeat center/contain;
    transform: translateY(-50%); }

.material-row .material-card {
  padding-left: 30px; }

.material-card__new {
  position: absolute;
  top: 36px;
  left: -3px;
  z-index: -1;
  display: flex;
  width: 50px;
  padding: 3px;
  padding-left: 6px;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  background: linear-gradient(to right, #ff0000 40%, #fff);
  border-radius: 4px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  transform: rotate(-65deg); }
  .material-card__new::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    display: block;
    width: 7px;
    height: 7px;
    border: 1px solid #fff;
    border-radius: 50%;
    transform: translateY(-50%); }

.material-table {
  width: 100%;
  margin-top: 40px; }

.material-table__header {
  display: flex;
  flex-direction: row;
  height: auto; }

.material-table__header-box {
  width: 50%;
  text-align: center;
  padding-bottom: 20px; }

.material-table__header-box:first-of-type .material-table__header-img {
  width: 165px; }

.material-table__header-box:last-of-type .material-table__header-img {
  width: 220px; }

.material-table__year {
  width: 100%;
  background-color: #F2F2F2;
  padding: 10px 0;
  text-align: center;
  height: auto; }

.material-table__year-text {
  font-family: "Panton", Arial, sans-serif;
  color: #000;
  margin: 0;
  font-weight: 600;
  font-size: 16px; }

.material-table__quarter-header {
  padding: 10px 0;
  border-bottom: 1px solid #E0E0E0;
  text-align: center;
  position: relative;
  height: auto;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-right: none;
  border-top: none;
  border-left: none; }

.material-table__quarter-header::after {
  content: '';
  width: 222px;
  height: 1px;
  background-color: #688157;
  position: absolute;
  bottom: -1px;
  left: 50%;
  margin-left: -111px; }

.material-table__quarter-header.active .material-table__open-icon::after {
  transform: rotate(0deg); }

.material-table__quarter-title {
  font-family: "Panton", Arial, sans-serif;
  color: #000;
  margin: 0;
  font-size: 16px; }

.material-table__open-icon {
  width: 20px;
  height: 20px;
  border: 1px solid #688157;
  border-radius: 20px;
  position: absolute;
  left: 20px;
  top: 10px; }

.material-table__open-icon::before {
  content: '';
  width: 10px;
  height: 1px;
  background-color: #688157;
  position: absolute;
  top: 9px;
  left: 4px; }

.material-table__open-icon::after {
  content: '';
  width: 10px;
  height: 1px;
  background-color: #688157;
  position: absolute;
  top: 9px;
  left: 4px;
  transition: .25s all ease;
  transform: rotate(90deg); }

.material-table__quarter-content {
  width: 50%;
  padding: 40px 15px 0;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.material-table__quarter-logo-box {
  width: 100%;
  display: none; }

.material-table__quarter-logo {
  margin-bottom: 40px;
  width: 150px;
  max-width: 100%; }

.material-table__quarter-content-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: .25s max-height ease; }

.material-table__quarter-content:nth-of-type(1) {
  border-right: 1px solid #E0E0E0; }

.material-table__material {
  width: 25%;
  text-align: center;
  margin-bottom: 35px; }

.material-table__material-img {
  height: 81px;
  margin-bottom: 10px; }

.material-table__material-name {
  font-family: "ProximaNova", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin: 0; }

.material-table__no-material-text {
  font-family: "Panton", Arial, sans-serif;
  font-weight: 300;
  margin: 0 0 105px;
  font-size: 16px;
  display: inline-block; }

.material-table__no-access-text {
  font-family: "Panton", Arial, sans-serif;
  font-weight: 300;
  margin: 0 30px 10px 0;
  font-size: 16px;
  display: inline-block; }

.material-table__no-access-box {
  margin-bottom: 95px; }

.material-table__buy-btn {
  display: inline-block;
  font-family: "Panton", Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 25px 10px;
  color: #688157;
  border: 1px solid #688157;
  transition: .25s all ease; }

.material-table__buy-btn:hover, .material-table__buy-btn:focus {
  text-decoration: none;
  color: #fff;
  background-color: #688157; }

.material-filter {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  margin-top: 25px;
  align-items: center;
  flex-wrap: wrap; }

.material-filter__title {
  font-family: "Panton", Arial, sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: normal;
  margin-right: 45px;
  margin-bottom: 0; }

.material-filter__select {
  font-family: "Panton", Arial, sans-serif;
  font-size: 14px;
  color: #000;
  border: none;
  border-bottom: 1px solid #E0E0E0;
  padding: 8px 4px;
  width: 160px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer; }

.material-filter__select-wrap {
  display: inline-block;
  position: relative;
  cursor: pointer; }

.material-filter__select-wrap:first-of-type {
  margin-right: 64px; }

.material-filter__select-wrap::before {
  content: '';
  width: 7px;
  height: 1px;
  display: block;
  background-color: #000;
  position: absolute;
  top: 16px;
  right: 5px;
  transform: rotate(35deg); }

.material-filter__select-wrap::after {
  content: '';
  width: 7px;
  height: 1px;
  display: block;
  background-color: #000;
  position: absolute;
  top: 16px;
  right: 0px;
  transform: rotate(-35deg); }

.materials {
  width: 100%; }

@media screen and (max-width: 991px) {
  .material-table__material-img {
    height: 65px; }
  .material-table__material-name {
    font-size: 12px;
    font-weight: 600; } }

@media (max-width: 767px) {
  .material-card:not(:last-of-type) {
    margin-bottom: 20px; }
  .material-table__quarter-content {
    width: 100%; }
  .material-table__quarter-content-box {
    flex-wrap: wrap; }
  .material-table__header {
    display: none; } }

@media (max-width: 601px) {
  .material-filter {
    display: block; }
  .material-filter__title {
    margin-bottom: 15px; } }

.question-field {
  width: 100%;
  resize: none; }

.ax_horizontal_line {
  width: 100%; }

@media screen and (max-width: 480px) {
  .question-field {
    font-size: 14px !important; } }

.order__table-td {
  text-align: left; }

.table-row-info__materials-btn {
  display: none;
  font-family: "Panton", Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 25px 10px;
  color: #336666;
  border: 1px solid #336666;
  transition: .25s all ease;
  margin-top: 10px; }

.table-row-info__materials-btn:hover, .table-row-info__materials-btn:focus {
  text-decoration: none;
  color: #fff;
  background-color: #336666; }

.table-row-info__materials-btn.active {
  display: inline-block; }

@media screen and (max-width: 991px) {
  .order__table th {
    text-align: center; }
  .order__table-td {
    text-align: center; }
  .order__list .table-row {
    padding: 10px 0; }
  .order__pay {
    flex-direction: column;
    align-items: center; }
  .order__hidden--lg {
    display: none; } }

@media (max-width: 767px) {
  .order__list .table-row {
    background-color: #fff; }
  .row.table-row {
    position: relative;
    display: flex;
    margin-bottom: -1px;
    flex-direction: column;
    border: 1px solid lightgrey !important; }
    .row.table-row.order__hidden {
      display: none; }
    .row.table-row.selected-row {
      border-bottom-color: transparent !important;
      background-color: #f0f0f0; }
      .row.table-row.selected-row + .table-row-info {
        background-color: #f0f0f0; }
  .order__hidden {
    display: none; }
  .row.table-row > .order__number {
    order: 1;
    position: relative;
    justify-content: flex-start;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000000 !important; }
    .row.table-row > .order__number p {
      margin: 0; }
    .row.table-row > .order__number::before {
      content: 'Заказ №';
      margin-right: 5px;
      align-self: center; }
    .row.table-row > .order__number::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 15px;
      display: block;
      width: 55px;
      height: 2px;
      background-color: #336666; }
  .row.table-row > .order__status {
    order: 2;
    height: 35px;
    justify-content: flex-start; }
    .row.table-row > .order__status p {
      margin: 0; }
    .row.table-row > .order__status br {
      display: none; }
  .row.table-row > .order__pay {
    order: 3;
    display: flex !important;
    height: 25px;
    flex-direction: row;
    justify-content: flex-start; }
    .row.table-row > .order__pay p {
      margin: 0; }
    .row.table-row > .order__pay .get_bill {
      margin-left: 20px; }
  .row.table-row > .order__date {
    order: 4;
    height: 25px;
    justify-content: flex-start; }
    .row.table-row > .order__date p {
      margin: 0; }
    .row.table-row > .order__date::before {
      content: 'Дата приобретения';
      margin-right: 5px;
      align-self: center; }
  .row.table-row > .order__cost {
    order: 5;
    height: 25px;
    justify-content: flex-start; }
    .row.table-row > .order__cost p {
      margin: 0; }
    .row.table-row > .order__cost::before {
      content: 'Стоимость:';
      margin-right: 5px;
      align-self: center; }
  .row.table-row > .order__more {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex !important; }
    .row.table-row > .order__more .show-more-plus {
      font-size: 35px; }
  .row.table-row-info {
    margin-bottom: -1px;
    border: 1px solid lightgrey !important;
    border-top: none !important;
    background-color: #fff; }
  .order__table {
    display: block;
    width: 80% !important;
    margin: 0 auto;
    border: none; }
    .order__table thead {
      display: none; }
    .order__table tbody {
      display: flex;
      flex-direction: column; }
    .order__table tr {
      display: flex;
      flex-direction: column; }
      .order__table tr:first-of-type {
        padding-top: 10px;
        border-top: 1px solid lightgrey; }
      .order__table tr:not(:last-of-type) {
        padding-bottom: 10px;
        border-bottom: 1px solid lightgrey; }
      .order__table tr:not(:first-of-type) {
        padding-top: 10px; }
    .order__table td {
      width: 100%;
      display: flex;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      border: none !important; }
  .order__list .table-responsive {
    border: none !important; }
  .order__table-title::before {
    content: 'Курс';
    font-weight: bold;
    margin-right: auto; }
  .order__table-number::before {
    content: '№';
    font-weight: bold;
    margin-right: auto; }
  .order__table-quarter::before {
    content: 'Квартал';
    font-weight: bold;
    margin-right: auto; }
  .order__table-year::before {
    content: 'Год';
    font-weight: bold;
    margin-right: auto; }
  .order__table-status::before {
    content: 'Статус';
    font-weight: bold;
    margin-right: auto; }
  .order__table-td {
    width: 50%;
    text-align: left;
    white-space: normal; } }

@media screen and (max-width: 480px) {
  .order__table {
    width: 100% !important; }
  .order__table-td {
    width: 60%; } }

.row--top {
  margin-top: 24px; }

.b-popup-content {
  max-width: 400px;
  width: 100% !important;
  margin: 0 !important;
  transform: translate(-50%, -50%); }

.catalog-table-fix::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px; }

.catalog-table-fix::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.9);
  border-radius: 16px; }

@media screen and (max-width: 1200px) {
  .catalog {
    margin-top: 20px; }
  .catalog .card {
    height: 240px;
    min-height: 240px; }
  .catalog .card__card-img {
    width: 86%;
    height: auto; } }

@media screen and (max-width: 991px) {
  .row--top {
    margin-top: 0; }
  .cards-container {
    display: flex;
    width: 100%; }
    .cards-container > .row {
      display: flex;
      width: 49%;
      margin: 0;
      flex-direction: column; }
      .cards-container > .row .iron,
      .cards-container > .row .cross {
        width: 100%;
        margin-bottom: 10px;
        padding: 0; }
      .cards-container > .row:first-child {
        margin-right: 10px; }
    .cards-container > .row--top {
      margin-left: 2%; }
    .cards-container .card__card-img {
      max-width: 86%;
      width: auto;
      height: auto; }
  .catalog-footer-container .row {
    display: flex;
    flex-direction: column; }
  .catalog-footer-container .catalog-price {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 10px; }
  .catalog-footer-container .custom-button {
    width: 50%;
    margin: 0 auto; }
  .catalog-footer-container .cost {
    order: 1; }
  .catalog-footer-container .order {
    order: 2; }
  .catalog-footer-container .description {
    order: 3;
    margin-top: 30px; } }

@media (max-width: 767px) {
  .catalog .top-line {
    width: 33%; } }

@media (max-width: 601px) {
  .cards-container .card__card-img {
    max-width: 100%;
    align-self: center; }
  .row .card__card-body {
    width: 90%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto; }
  .row .card__card-footer {
    margin-top: 5px; }
  .catalog .table-responsive th {
    vertical-align: middle; }
  .catalog .table-responsive th:first-child,
  .catalog .table-responsive td:first-child {
    width: 50%;
    white-space: normal; }
  .catalog .table-responsive th:nth-child(2),
  .catalog .table-responsive td:nth-child(2) {
    width: 30%;
    white-space: normal;
    text-align: center; }
  .catalog .table-responsive th:nth-child(3),
  .catalog .table-responsive td:nth-child(3) {
    width: 20%;
    white-space: normal; } }

@media screen and (max-width: 480px) {
  .header-container > div > div > h1,
  .header-container > h1 {
    font-size: 26px !important; }
  .custom-sub-header {
    font-size: 18px; }
  .card__card-title {
    margin-left: 12px; }
  .card__card-header .checkbox {
    width: 15px;
    height: 15px; }
  .catalog .card {
    height: 190px;
    min-height: 190px; }
  .catalog-footer-container .catalog-price,
  .catalog-footer-container .custom-button {
    width: 60%; }
  .card .card__card-body-cross {
    margin-top: 10px; }
  .popup-buttons-wrap {
    flex-direction: column;
    align-items: center; }
    .popup-buttons-wrap .custom-button:not(:last-child) {
      margin-bottom: 20px; }
  .b-popup-content {
    width: 281px !important; }
  .card__card-footer-price--accent {
    margin-bottom: 0; }
  .catalog .card {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto; }
  .card__card-title {
    font-size: 16px; }
  .cards-container .card__card-header > input {
    margin-top: 8px;
    margin-right: 8px; } }

.checkout {
  margin-bottom: 20px; }
  .checkout .order-popup {
    margin-bottom: 20px; }
  .checkout .row.fixed-height-row-big > div {
    height: auto; }
  .checkout .form__data-group {
    margin-top: 20px; }
  .checkout .fixed-height-row-big {
    display: flex;
    flex-wrap: wrap; }

@media screen and (max-width: 1200px) {
  .checkout {
    margin-top: 20px; }
  .checkout .container {
    width: 100%; }
  .checkout .input-small > input {
    width: 100%; }
  .checkout .input-small .select2-container {
    width: 100% !important; }
  .checkout .checkbox .checkbox-button {
    width: 100%; } }

@media screen and (max-width: 991px) {
  .checkout--row {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    margin-top: 20px; }
    .checkout--row .order-popup {
      width: 100%; }
    .checkout--row .input-small-promo {
      width: 276px;
      margin-right: auto; }
    .checkout--row .custom-button-small {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 8px;
      margin-left: 5px;
      margin-right: 5px; }
  .auth-wrapper .input-small {
    max-width: 411px; }
  .checkout .custom-button {
    max-width: 411px; }
  .checkout .custom-label {
    display: none; } }

@media (max-width: 767px) {
  .checkout .margin-fix {
    margin-top: 20px; }
  .checkout--row {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .checkout--row .input-small-promo {
      width: 70%;
      margin-right: 0; }
    .checkout--row .custom-button-small {
      width: 70%;
      margin-left: 0;
      margin-right: 0; }
  .top-line {
    width: 33% !important; }
  .checkout .fixed-height-row-big {
    display: block; } }

@media screen and (max-width: 480px) {
  .checkout--row .input-small-promo {
    width: 100%; }
  .checkout--row .custom-button-small {
    width: 100%; }
  .column-register-title {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 17px;
    color: #ffffff; }
    .column-register-title::before {
      display: none; }
  .checkout .form__action-group {
    padding: 15px;
    background-color: #336666;
    border-radius: 15px; }
  .checkout .form__action-btn {
    color: #ffffff;
    border-bottom-color: #fff; }
  .checkout .checkbox .checkbox-button {
    display: inline-block;
    width: auto;
    background-color: transparent;
    color: #000000;
    border: none; }
  .checkout .form__data-group {
    margin-top: 0; }
  .checkout--row .order-popup {
    width: 276px; }
  .not-auth-actions {
    width: 100%; } }

.password-resest-wrapper {
  margin-top: 20px; }

@media (max-width: 767px) {
  .password-resest-wrapper {
    max-width: 411px; }
    .password-resest-wrapper .input-small > input {
      width: 100%; }
  .change-password__buttons {
    max-width: 411px; } }

@media (max-width: 767px) {
  .auth-wrapper {
    max-width: 411px; }
    .auth-wrapper .input-small input {
      width: 100%; }
  .auth__buttons {
    max-width: 411px; } }

.promo_header {
  font-size: 30px;
  font-weight: normal; }

.main_promo {
  padding-top: 57px; }

.ben_bl {
  width: calc((100% - 10px * 3) / 4);
  margin-bottom: 40px;
  margin-right: 10px; }
  .ben_bl img {
    width: 100%;
    height: auto; }
  .ben_bl:nth-child(4n) {
    margin-right: 0; }

.item_ben {
  height: auto; }

.benefits_blocks {
  align-items: baseline; }

.bl_iron {
  width: calc((100% - 62px * 3) / 4);
  margin-right: 62px; }
  .bl_iron:nth-child(4n) {
    margin-right: 0; }

.posters_blocks {
  align-items: baseline; }

.poster_bl {
  width: calc((100% - 10px * 5) / 6);
  margin-right: 10px; }
  .poster_bl:nth-child(6n) {
    margin-right: 0; }

@media screen and (max-width: 1180px) {
  .promo_header {
    padding-bottom: 10px; } }

@media screen and (max-width: 991px) {
  .ben_bl {
    width: calc((100% - 10px * 2) / 3); }
    .ben_bl:nth-child(4n) {
      margin-right: 10px; }
    .ben_bl:nth-child(3n) {
      margin-right: 0; }
  .bl_iron {
    width: calc((100% - 20px * 3) / 4);
    margin-right: 20px; }
  .poster_bl {
    width: calc((100% - 10px * 4) / 5); }
    .poster_bl:nth-child(6n) {
      margin-right: 10px; }
    .poster_bl:nth-child(5n) {
      margin-right: 0; } }

@media (max-width: 767px) {
  .poster_bl {
    width: calc((100% - 10px * 3) / 4); }
    .poster_bl:nth-child(5n) {
      margin-right: 10px; }
    .poster_bl:nth-child(4n) {
      margin-right: 0; } }

@media (max-width: 601px) {
  .ben_bl {
    width: calc((100% - 10px) / 2); }
    .ben_bl:nth-child(3n) {
      margin-right: 10px; }
    .ben_bl:nth-child(2n) {
      margin-right: 0; }
  .bl_iron {
    width: calc((100% - 20px * 2) / 3); }
    .bl_iron:nth-child(4n) {
      margin-right: 20px; }
    .bl_iron:nth-child(3n) {
      margin-right: 0; }
  .poster_bl {
    width: calc((100% - 10px * 2) / 3); }
    .poster_bl:nth-child(4n) {
      margin-right: 10px; }
    .poster_bl:nth-child(3n) {
      margin-right: 0; } }

@media screen and (max-width: 480px) {
  .main_promo {
    padding-top: 20px; }
  .promo_header {
    margin-right: 0;
    padding-bottom: 4px;
    font-size: 26px; }
  .ben_bl {
    width: 100%;
    margin-right: 0; }
    .ben_bl:nth-child(4n) {
      margin-right: 0; }
    .ben_bl:nth-child(3n) {
      margin-right: 0; }
    .ben_bl:nth-child(2n) {
      margin-right: 0; }
  .bl_iron {
    width: calc((100% - 20px) / 2); }
    .bl_iron:nth-child(3n) {
      margin-right: 20px; }
    .bl_iron:nth-child(2n) {
      margin-right: 0; }
  .poster_bl {
    width: calc((100% - 10px) / 2); }
    .poster_bl:nth-child(3n) {
      margin-right: 10px; }
    .poster_bl:nth-child(2n) {
      margin-right: 0; } }

.checkbox {
  display: block;
  position: relative;
  color: #252525;
  font-size: 20px;
  line-height: 1.25;
  margin-top: 10px;
  cursor: pointer; }
  .checkbox a {
    color: #323D81;
    border-bottom-color: #323D81; }

.checkbox--error::before {
  border-color: #ff4433; }

.checkbox__text {
  position: relative;
  display: block;
  min-height: 30px;
  padding: 0 0 0 65px; }
  .checkbox__text a {
    color: #55a2eb;
    border-bottom: 1px solid #55a2eb;
    transition: border-color 0.3s; }
    .checkbox__text a:hover, .checkbox__text a:focus {
      border-bottom-color: transparent;
      outline: none; }
  .checkbox__text::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #252525;
    cursor: pointer; }
  .checkbox__text::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7px;
    width: 16px;
    height: 16px;
    background: #252525;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s; }
  .checkbox__text:hover::after {
    opacity: 0.2; }

.checkbox__hidden-control {
  top: calc(50% + 15px);
  left: 15px; }

.checkbox__hidden-control:checked + .checkbox__text::after {
  opacity: 1; }

@media screen and (max-width: 1280px) {
  .checkbox__text {
    font-size: 16px;
    padding: 0 0 0 45px; }
    .checkbox__text::before {
      width: 25px;
      height: 25px; }
    .checkbox__text::after {
      left: 6px;
      width: 14px;
      height: 14px; } }

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.65);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; }

.popup--hidden {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, z-index 0s linear 0.3s; }

.popup__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer; }

.popup__window {
  position: relative;
  overflow-y: auto;
  width: 616px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 50px);
  background-size: cover;
  background-position: center;
  background-color: #fff; }
  .popup__window--promo {
    width: 804px;
    height: 800px;
    padding: 50px 75px 150px; }

.popup__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.popup__close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transition: opacity 0.15s ease;
  background-color: transparent;
  border: 0;
  cursor: pointer; }
  .popup__close:hover, .popup__close:focus {
    outline: none;
    opacity: 0.75; }

.popup__plane {
  margin-bottom: 20px; }

.popup__text-wrap {
  padding: 15px; }

.popup__title {
  display: block;
  font-weight: 700;
  font-size: 44px;
  line-height: 1.2;
  text-align: center; }

.popup__subtitle {
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.16;
  text-align: center;
  margin-top: 15px;
  padding: 0 50px; }

.popup__description {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.3;
  text-align: center; }

.popup__form {
  margin-top: 100px; }

.popup__success {
  display: none; }

.popup__window--success {
  display: flex;
  align-items: center;
  justify-content: center; }
  .popup__window--success > * {
    display: none; }
  .popup__window--success .popup__success {
    display: block; }
  .popup__window--success .popup__form {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    left: -999px;
    top: -999px; }
  .popup__window--success .popup__close {
    display: block; }

.visually-hidden {
  position: absolute !important;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

@media screen and (max-width: 1280px) {
  .popup__window--promo {
    width: 600px;
    height: 500px;
    padding: 50px 50px 60px; }
  .popup__title {
    font-size: 32px; }
  .popup__subtitle {
    font-size: 22px;
    padding: 0; }
  .popup__form {
    margin-top: 50px; }
  .popup__close {
    top: 0;
    right: 0; }
    .popup__close svg {
      width: 25px;
      height: 25px; } }

@media (max-width: 601px) {
  .popup__window--promo {
    padding: 40px 15px; }
  .popup__title {
    font-size: 26px; }
  .popup__subtitle {
    font-size: 18px; }
  .popup__form {
    margin-top: 30px; } }

.popup-form {
  display: flex;
  flex-direction: column;
  align-items: center; }

.popup-form__input-title {
  display: block;
  width: 100%;
  font-size: 32px;
  text-align: center;
  margin-bottom: 10px; }

.popup-form__input-wrap {
  width: 100%; }

.popup-form__input {
  width: 100%;
  height: 95px;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 40px;
  border: 0; }

.popup-form__submit {
  width: 460px;
  height: 100px;
  color: #fff;
  font-size: 42px;
  margin: 50px auto 0;
  text-align: center;
  background-color: rgba(255, 0, 0, 0.5);
  transition: background-color 0.1s ease;
  text-transform: uppercase;
  font-weight: 600;
  border: 0; }
  .popup-form__submit:hover, .popup-form__submit:focus {
    outline: none;
    background-color: rgba(255, 0, 0, 0.7); }
  .popup-form__submit:active {
    background-color: red; }

.popup-form__error {
  color: #ff4433;
  margin-top: 15px;
  text-align: center; }
  .popup-form__error--hidden {
    display: none; }

@media screen and (max-width: 1280px) {
  .popup-form__input-title {
    font-size: 18px; }
  .popup-form__input {
    height: 50px;
    font-size: 20px; }
  .popup-form__submit {
    width: 100%;
    height: 50px;
    font-size: 22px;
    margin: 30px auto 0; } }

@media (max-width: 601px) {
  .popup-form__input-title {
    font-size: 16px; } }

.open-promo-popup-btn {
  width: 110px;
  height: 100px;
  position: fixed;
  top: 60%;
  right: 0;
  font-size: 18px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #fff;
  transition: background-color 0.15s ease;
  z-index: 5;
  border: 0; }
  .open-promo-popup-btn:hover, .open-promo-popup-btn:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.25); }

.open-promo-popup-btn__percent-sign {
  font-size: 40px;
  color: #D60000; }

.promo-popup {
  color: #fff; }
  .promo-popup .popup__close svg {
    fill: white; }
  .promo-popup .checkbox {
    color: inherit; }
  .promo-popup .checkbox__text::before {
    border-color: #fff;
    background-color: #fff; }

.open-promo-popup-btn__title-text {
  display: block;
  margin-bottom: 4px; }

@media screen and (max-width: 1280px) {
  .open-promo-popup-btn {
    font-size: 14px;
    width: 90px;
    height: 80px; }
  .open-promo-popup-btn__percent-sign {
    font-size: 28px; } }
