.question-field {
  width: 100%;
  resize: none;
}

.ax_horizontal_line {
  width: 100%;
}

@include phone-lg {
  .question-field {
    font-size: 14px !important;
  }
}