.row--top {
  margin-top: 24px;
}

.b-popup-content {
  max-width: 400px;
  width: 100% !important;
  margin: 0 !important;
  transform: translate(-50%, -50%);
}

.catalog-table-fix {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#333, 0.9);
    border-radius: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .catalog {
    margin-top: 20px;
  }

  .catalog {
    .card {
      height: 240px;
      min-height: 240px;
    }
  }

  .catalog {
    .card__card-img {
      width: 86%;
      height: auto;
    }
  }
}

@include tablet-lg {
  .row--top {
    margin-top: 0;
  }

  .cards-container {
    display: flex;
    width: 100%;

    & > .row {
      display: flex;
      width: 49%;
      margin: 0;
      flex-direction: column;

      .iron,
      .cross {
        width: 100%;
        margin-bottom: 10px;
        padding: 0;
      }

      &:first-child {
        margin-right: 10px;
      }
    }

    & > .row--top {
      margin-left: 2%;
    }

    .card__card-img {
      max-width: 86%;
      width: auto;
      height: auto;
    }
  }

  .catalog-footer-container {
    .row {
      display: flex;
      flex-direction: column;
    }

    .catalog-price {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .custom-button {
      width: 50%;
      margin: 0 auto;
    }

    .cost {
      order: 1;
    }

    .order {
      order: 2;
    }

    .description {
      order: 3;

      margin-top: 30px;
    }
  }
}

@include tablet-md {
  .catalog {
    .top-line {
      width: 33%;
    }
  }
}

@include tablet-sm {
  .cards-container {
    .card__card-img {
      max-width: 100%;
      align-self: center;
    }
  }

  .row {
    .card__card-body {
      width: 90%;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }

    .card__card-footer {
      margin-top: 5px;
    }
  }

  .catalog {
    .table-responsive {
      th {
        vertical-align: middle;
      }

      th:first-child,
      td:first-child {
        width: 50%;

        white-space: normal;
      }

      th:nth-child(2),
      td:nth-child(2) {
        width: 30%;

        white-space: normal;
        text-align: center;
      }

      th:nth-child(3),
      td:nth-child(3) {
        width: 20%;

        white-space: normal;
      }
    }
  }
}

@include phone-lg {
  .header-container > div > div > h1,
  .header-container > h1 {
    font-size: 26px !important;
  }

  .custom-sub-header {
    font-size: 18px;
  }

  .card__card-title {
    margin-left: 12px;
  }

  .card__card-header {
    .checkbox {
      width: 15px;
      height: 15px;
    }
  }

  .catalog .card {
    height: 190px;
    min-height: 190px;
  }

  .catalog-footer-container {
    .catalog-price,
    .custom-button {
      width: 60%;
    }
  }

  .card {
    .card__card-body-cross {
      margin-top: 10px;
    }
  }

  .popup-buttons-wrap {
    flex-direction: column;
    align-items: center;

    .custom-button:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .b-popup-content {
    width: 281px !important;
  }

  .card__card-footer-price--accent {
    margin-bottom: 0;
  }

  .catalog .card {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .card__card-title {
    font-size: 16px;
  }

  .cards-container {
    .card__card-header > input {
      margin-top: 8px;
      margin-right: 8px;
    }
  }
}