.open-promo-popup-btn {
  width: 110px;
  height: 100px;
  position: fixed;
  top: 60%;
  right: 0;
  font-size: 18px;
  font-weight: bold;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  color: $white;
  transition: background-color 0.15s ease;
  z-index: 5;
  border: 0;

  &:hover,
  &:focus {
    outline: none;
    background-color: rgba(0,0,0,0.25);
  }
}

.open-promo-popup-btn__percent-sign {
  font-size: 40px;
  color: #D60000;
}

.promo-popup {
  color: $white;

  .popup__close {
    svg {
      fill: white;
    }
  }

  .checkbox {
    color: inherit;
  }

  .checkbox__text {

    &::before {
      border-color: $white;
      background-color: $white;
    }
  }
}

.open-promo-popup-btn__title-text {
  display: block;
  margin-bottom: 4px;
}

@include screen-md {
  .open-promo-popup-btn {
    font-size: 14px;
    width: 90px;
    height: 80px;
  }

  .open-promo-popup-btn__percent-sign {
    font-size: 28px;
  }


}
