@mixin screen-md() {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin tablet-lg() {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin tablet-md() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet-sm() {
  @media (max-width: 601px) {
    @content;
  }
}

@mixin phone-lg() {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin phone-md() {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin word-break {
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  -ms-word-wrap: break-word;

  @supports not (overflow-wrap: break-word) {
    word-wrap: break-word;
  }
}