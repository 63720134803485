.password-resest-wrapper {
  margin-top: 20px;
}

@include tablet-md {
  .password-resest-wrapper {
    max-width: 411px;

    .input-small > input {
      width: 100%;
    }
  }

  .change-password__buttons {
    max-width: 411px;
  }
}