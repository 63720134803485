.material-card {
  position: relative;

  padding-top: 1px;

  h1 {
    font-size: 21px;
  }
}

.material-card__number {
  font-family: "HelveticaNeueCyr-Light", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.material-card__description {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.material-card__link:not(:last-of-type) {
  margin-bottom: 5px;
}

.material-card__link--file {
  position: relative;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: -20px;

    display: block;
    width: 15px;
    height: 15px;

    background: url('#{$path-img}file.jpg') no-repeat center/contain;

    transform: translateY(-50%);
  }
}

.material-card__link--video {
  position: relative;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: -20px;

    display: block;
    width: 15px;
    height: 15px;

    background: url('#{$path-img}video.png') no-repeat center/contain;

    transform: translateY(-50%);
  }
}

.material-row .material-card {
  padding-left: 30px;
}

.material-card__new {
  position: absolute;
  top: 36px;
  left: -3px;
  z-index: -1;

  display: flex;
  width: 50px;
  padding: 3px;
  padding-left: 6px;

  justify-content: flex-start;
  align-items: center;

  font-size: 10px;

  background: linear-gradient(to right, #ff0000 40%, #fff);
  border-radius: 4px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.5);

  transform: rotate(-65deg);

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: 5px;

    display: block;
    width: 7px;
    height: 7px;

    border: 1px solid #fff;
    border-radius: 50%;

    transform: translateY(-50%);
  }
}

.material-table {
  width: 100%;
  margin-top: 40px;
}

.material-table__header {
  display: flex;
  flex-direction: row;
  height: auto;
}

.material-table__header-box {
  width: 50%;
  text-align: center;
  padding-bottom: 20px;
}

.material-table__header-box:first-of-type .material-table__header-img {
  width: 165px;
}

.material-table__header-box:last-of-type .material-table__header-img {
  width: 220px;
}

.material-table__year {
  width: 100%;
  background-color: #F2F2F2;
  padding: 10px 0;
  text-align: center;
  height: auto;
}

.material-table__year-text {
  font-family: "Panton", Arial, sans-serif;
  color: #000;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.material-table__quarter-header {
  padding: 10px 0;
  border-bottom: 1px solid #E0E0E0;
  text-align: center;
  position: relative;
  height: auto;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-right: none;
  border-top: none;
  border-left: none;
}

.material-table__quarter-header::after {
  content: '';
  width: 222px;
  height: 1px;
  background-color: #688157;
  position: absolute;
  bottom: -1px;
  left: 50%;
  margin-left: -111px;
}

.material-table__quarter-header.active .material-table__open-icon::after {
  transform: rotate(0deg);
}

.material-table__quarter-title {
  font-family: "Panton", Arial, sans-serif;
  color: #000;
  margin: 0;
  font-size: 16px;
}

.material-table__open-icon {
  width: 20px;
  height: 20px;
  border: 1px solid #688157;
  border-radius: 20px;
  position: absolute;
  left: 20px;
  top: 10px;
}

.material-table__open-icon::before {
  content: '';
  width: 10px;
  height: 1px;
  background-color: #688157;
  position: absolute;
  top: 9px;
  left: 4px;
}

.material-table__open-icon::after {
  content: '';
  width: 10px;
  height: 1px;
  background-color: #688157;
  position: absolute;
  top: 9px;
  left: 4px;
  transition: .25s all ease;
  transform: rotate(90deg);
}

.material-table__quarter-content {
  width: 50%;
  padding: 40px 15px 0;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.material-table__quarter-logo-box {
  width: 100%;
  display: none;
}

.material-table__quarter-logo {
  margin-bottom: 40px;
  width: 150px;
  max-width: 100%;
}

.material-table__quarter-content-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: .25s max-height ease;
}

.material-table__quarter-content:nth-of-type(1) {
  border-right: 1px solid #E0E0E0;
}

.material-table__material {
  width: 25%;
  text-align: center;

  margin-bottom: 35px;
}

.material-table__material-img {
  height: 81px;
  margin-bottom: 10px;
}

.material-table__material-name {
  font-family: "ProximaNova", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin: 0;
}

.material-table__no-material-text {
  font-family: "Panton", Arial, sans-serif;
  font-weight: 300;
  margin: 0 0 105px;
  font-size: 16px;
  display: inline-block;
}

.material-table__no-access-text {
  font-family: "Panton", Arial, sans-serif;
  font-weight: 300;
  margin: 0 30px 10px 0;
  font-size: 16px;
  display: inline-block;
}

.material-table__no-access-box {
  margin-bottom: 95px;
}

.material-table__buy-btn {
  display: inline-block;
  font-family: "Panton", Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 11px 25px 10px;
  color: #688157;
  border: 1px solid #688157;
  transition: .25s all ease;
}

.material-table__buy-btn:hover, .material-table__buy-btn:focus {
  text-decoration: none;
  color: #fff;
  background-color: #688157;
}

.material-filter {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  margin-top: 25px;
  align-items: center;
  flex-wrap: wrap;
}

.material-filter__title {
  font-family: "Panton", Arial, sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: normal;
  margin-right: 45px;
  margin-bottom: 0;
}

.material-filter__select {
  font-family: "Panton", Arial, sans-serif;
  font-size: 14px;
  color: #000;
  border: none;
  border-bottom: 1px solid #E0E0E0;
  padding: 8px 4px;
  width: 160px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.material-filter__select-wrap {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.material-filter__select-wrap:first-of-type {
  margin-right: 64px;
}

.material-filter__select-wrap::before {
  content: '';
  width: 7px;
  height: 1px;
  display: block;
  background-color: #000;
  position: absolute;
  top: 16px;
  right: 5px;
  transform: rotate(35deg);
}

.material-filter__select-wrap::after {
  content: '';
  width: 7px;
  height: 1px;
  display: block;
  background-color: #000;
  position: absolute;
  top: 16px;
  right: 0px;
  transform: rotate(-35deg);
}

.materials {
  width: 100%;
}

@include tablet-lg {
  .material-table__material-img {
    height: 65px;
  }
  .material-table__material-name {
    font-size: 12px;
    font-weight: 600;
  }
}

@include tablet-md {
  .material-card:not(:last-of-type) {
    margin-bottom: 20px;
  }
  .material-table__quarter-content {
    width: 100%;
  }
  .material-table__quarter-content-box {
    flex-wrap: wrap;
  }
  .material-table__header {
    display: none;
  }
}

@include tablet-sm {
  .material-filter {
    display: block;
  }
  .material-filter__title {
    margin-bottom: 15px;
  }
}