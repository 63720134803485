.single_news_title {
  position: relative;

  margin-right: 54px;
  padding-bottom: 40px;

  font-family: "pfdintextcondpro-medium", sans-serif;
  font-size: 30px;
  font-weight: normal;
  color: #658157;
  line-height:34px;
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 84px;
    height: 2px;

    background: #658157;
  }
}

.single_news_content {
  img {
    width: 50%;
    height: auto;
  }
}

#owl-carousel5 {
  .owl-dot {
    box-shadow: 0 0 0 1px rgba(#000, 0.5);
  }
}

@media screen and (max-width: 1180px) {
  .single_news_title {
    padding-bottom: 10px;
  }
}

@include tablet-lg {
  .single_news_content img {
    margin-right: 20px;
  }
}

@include tablet-sm {
  .single_news_content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      float: none;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

@include phone-lg {
  .single_news {
    margin-top: 20px;
  }

  .single_news_title {
    margin-right: 0;
    margin-bottom: 40px;
    padding-bottom: 4px;

    font-size: 26px;
  }

  .single_news_content {
    img {
      width: 80%;
    }
  }

  #owl-carousel5 {
    .owl-next,
    .owl-prev {
      top: 50%;

      transform: translateY(calc(-50% - 25px));
    }
  }
}